import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import './projectsStyling.css';
import {ProjectGrabber} from "./components/projectGrabber";
import ProjectsView from "./subProjects/projectsView";
import ProjectsViewMobile from "./subProjects/projectsViewMobile";

const ProjectsPage = (props) => {
    const [isMobile, setIsMobile] = useState(false);

    useLayoutEffect(() => {
        const updateIsMobile = () => {
            if (window.innerWidth < 800) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };
        updateIsMobile();
        window.addEventListener('resize', updateIsMobile);
        return () => window.removeEventListener('resize', updateIsMobile);
    }, []);

    const [openProject, setOpenProject] = useState(null);
    const sectionRef = useRef(null);

    useEffect(() => {
        if (openProject) {
            sectionRef.current.scrollIntoView();
        }
    }, [openProject]);

    const handleOpenProject = useCallback((projectTitle) => {
        if (openProject === projectTitle) {
            setOpenProject(null);
        } else {
            setOpenProject(projectTitle);
        }
    }, [openProject]);

    const classToUse = useMemo(() => {
        switch (props.originalVariable) {
            case 'about':
                return 'right-projects-body';
            case 'contact':
                return 'left-projects-body';
            case null:
                return props.prevComponent === 'projects' ? 'right-projects-body' : 'projects-body';
            default:
                return 'projects-body';
        }
    }, [props.originalVariable, props.prevComponent]);

    return (
        <div className={classToUse}>
            <h1 className="projects-header">
                Check out my Projects
            </h1>
            <div className="cards-container">
                {ProjectGrabber.map(project => (
                    <div key={project.id}>
                        <div
                            className="game-card"
                            onClick={() => handleOpenProject(project.title)}
                        >
                            <img src={project.image} alt={project.title}/>
                        </div>
                    </div>
                ))}
            </div>
            {openProject != null ? (
                <section className="expanded-project-view" ref={sectionRef}>
                    {isMobile ?
                        <ProjectsViewMobile isMobile={isMobile} selectedProject={openProject}
                                            handleOpenProject={handleOpenProject}/>
                        :
                        <ProjectsView isMobile={isMobile} selectedProject={openProject}
                                      handleOpenProject={handleOpenProject}/>
                    }
                </section>
            ) : null}
        </div>
    );
}

export default ProjectsPage;