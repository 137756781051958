import React, {useEffect, useState} from 'react';
import {Button, Card} from "react-bootstrap";
import ProjectSelectedPhoto from "./utility/projectSelectedPhoto";
import ProjectPageImages from "./utility/projectPageImages";
import {ProjectPageGrabber} from "./utility/projectPageGrabber";
import ProjectsComingSoon from "./projectsComingSoon";
import {fixOverflow} from "../../../baseUtilities/fixOverflow";

const ProjectsView = (props) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [openProject, setOpenProject] = useState(props.selectedProject);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (openProject !== props.selectedProject) {
            setOpenProject(props.selectedProject);
            setSelectedImage(null);
        }
    }, [props.selectedProject, openProject]);


    useEffect(() => {
        const updateIsMobile = () => {
            if (window.innerWidth < 800) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };
        updateIsMobile();
        window.addEventListener('resize', updateIsMobile);
        return () => window.removeEventListener('resize', updateIsMobile);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            fixOverflow("project-view-description-text");
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);


    let isFullProject = false;
    const projectTitle = props.selectedProject;
    if (projectTitle !== 'Future Projects') {
        isFullProject = true;
    }

    const handleOpenProject = props.handleOpenProject;

    const project = ProjectPageGrabber.find(project => project.title === projectTitle);

    const images = project.imageSet;

    if (selectedImage === null) {
        setSelectedImage(images[0]);
    }


    if (isFullProject) {
        return (
            <>
                <Card className="interior-project-page-view" key={project.id}>
                    <Card className="project-view-header">
                        <h1 className="project-view-header-title">
                            <b>
                                {project.title}
                            </b>
                        </h1>
                        <div className="project-view-header-exit">
                            <Button className="exit-button" title='Exit'
                                    onClick={() => handleOpenProject(project.title)}>
                                <b>
                                    X
                                </b>
                            </Button>
                        </div>
                    </Card>
                    <Card className={isMobile ?
                        "project-view-image-section-mobile" :
                        "project-view-image-section"}>
                        <ProjectPageImages images={images} isMobile={isMobile} setSelectedImage={setSelectedImage}/>
                        <ProjectSelectedPhoto image={selectedImage}/>
                    </Card>
                    <Card className="project-view-description">
                        <p className="project-view-description-header">
                            {project.descriptionHeader}
                        </p>
                        <p className="project-view-description-text">
                            {project.description}
                        </p>
                    </Card>
                    <Card className="project-view-itch" dangerouslySetInnerHTML={{__html: project.itchLink}}/>
                </Card>
            </>
        );
    } else {
        return (
            <>
                <ProjectsComingSoon
                    selectedProject={projectTitle}
                    handleOpenProject={handleOpenProject}
                />
            </>
        );
    }

}

export default ProjectsView;
