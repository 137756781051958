import React from "react";
import RoutingInfo from "./components/navbar/routing/RoutingInfo";
import {Amplify} from 'aws-amplify';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

const App = () => {
    return (
        <RoutingInfo/>
    )
}

export default App;
