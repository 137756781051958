import tower from '../../../filesToUse/images/towerOfHanoiAssets/towerMainMenu.png'
import prison from '../../../filesToUse/images/prisonEscapeAssets/MainMenu.png';
import soon from '../../../filesToUse/images/comingSoon.jpg'

export const ProjectGrabber = [
    {
        id: 1, title: 'Tower of Hanoi Redux', image: tower
    },
    {
        id: 2, title: 'Prison Escape Freedom Awaits', image: prison
    },
    {
        id: 3, title: 'Future Projects', image: soon
    }
];
