import React from 'react';

const AboutBody = () => {
    return (
        <div>
            <div>
                <h5>
                    Possessing a wide range of language proficiency, with a strong emphasis on Java, Python, and C#, I
                    am an expert in fostering team cohesion for optimized information exchange and productivity. My
                    exceptional communication abilities, combined with a methodical and detail-oriented approach, make
                    me an asset in solving complex problems and developing highly scalable applications and software. I
                    am eager to put my skills to the test and drive innovation.
                </h5>
                <br/>
                <br/>
            </div>
        </div>
    );
};

export default AboutBody;