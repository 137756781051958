import {Button, Card} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {ProjectPageGrabber} from "./utility/projectPageGrabber";
import {fixOverflow} from "../../../baseUtilities/fixOverflow";


const ProjectsComingSoon = (props) => {
    const [openProject, setOpenProject] = useState(props.selectedProject);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (openProject !== props.selectedProject) {
            setOpenProject(props.selectedProject);
        }
    }, [props.selectedProject, openProject]);


    useEffect(() => {
        const updateIsMobile = () => {
            if (window.innerWidth < 576) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };
        updateIsMobile();
        window.addEventListener('resize', updateIsMobile);
        return () => window.removeEventListener('resize', updateIsMobile);
    }, []);

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
            fixOverflow("soon-project-view-description-text");
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const projectTitle = props.selectedProject;

    const handleOpenProject = props.handleOpenProject;

    const project = ProjectPageGrabber.find(project => project.title === projectTitle);


    return (
        <>
            <Card className="soon-interior-project-page-view" key={project.id}>
                <Card className="project-view-header">
                    <h1 className="project-view-header-title">
                        <b>
                            {project.title}
                        </b>
                    </h1>
                    <div className="project-view-header-exit">
                        <Button className="exit-button" title='Exit'
                                onClick={() => handleOpenProject(project.title)}>
                            <b>
                                X
                            </b>
                        </Button>
                    </div>
                </Card>

                <Card className="soon-project-view-description">
                    <h4 className="soon-project-view-description-header">
                        <b>
                            {project.descriptionHeader}
                        </b>
                    </h4>
                    <p className="soon-project-view-description-text">
                        {project.description}
                    </p>
                </Card>
            </Card>
        </>
    );
};

export default ProjectsComingSoon;