import React, {useState} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import AboutMe from "../../../pages/about/aboutMe";
import ProjectsPage from "../../../pages/projects/projectsPage";
import ContactMe from "../../../pages/contact/contactMe";
import Footer from "../../footer/footer";
import "../NewNavBarStyling.css";
import NavBar from "../navBar";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

const RoutingInfo = () => {
    const [childVariable, setChildVariable] = useState(null);
    const [originalVariable, setOriginalVariable] = useState(null);
    const token = process.env.REACT_APP_RECAPTCHA_KEY;

    function handleChildVariable(newValue) {
        setOriginalVariable(childVariable);
        setChildVariable(newValue);
    }

    return (
        <Router>
            <section className="nav-header">
                <NavBar handleChildVariable={handleChildVariable}/>
            </section>
            <section className="body">
                <Routes>
                    <Route
                        path="/"
                        element={<AboutMe prevComponent={childVariable} originalVariable={originalVariable}/>}
                        state={{component: "about"}}
                    />
                    <Route
                        path="/projects"
                        element={<ProjectsPage prevComponent={childVariable}
                                               originalVariable={originalVariable}/>}
                        state={{component: "projects"}}
                    />
                    <Route
                        path="/contact"
                        element={
                            <GoogleReCaptchaProvider reCaptchaKey={token}>
                                <ContactMe prevComponent={childVariable} originalVariable={originalVariable}/>
                            </GoogleReCaptchaProvider>
                        }
                        state={{component: "contact"}}
                    />
                </Routes>
            </section>
            <Footer/>
        </Router>
    );
};

export default RoutingInfo;