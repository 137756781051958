import React, {useCallback, useMemo} from 'react';
import '../../projectsStyling.css';

const ProjectPageImages = ({isMobile, images, setSelectedImage}) => {
    const handleClick = useCallback((image) => {
        setSelectedImage(image);
    }, [setSelectedImage]);

    const classToUse = useMemo(() => {
        return isMobile ? "project-images-holder-mobile" : "project-images-holder"
    }, [isMobile])

    return (
        <div className={classToUse}>
            {images.map(image => (
                <img onClick={() => handleClick(image)} className="project-images" key={image.id} src={image.image}
                     alt='game'/>
            ))}
        </div>
    );
};

export default ProjectPageImages;
