export const fixOverflow = (nameToFind) => {
    const description = document.querySelectorAll('.' + nameToFind);
    description.forEach(function (newTextFont, i) {
        let divHeight = newTextFont.getBoundingClientRect().height;
        let scrollHeight = newTextFont.scrollHeight;
        let style = window.getComputedStyle(newTextFont);
        let fontSize = parseFloat(style.fontSize);
        //shrink font size if text has overflow
        if (scrollHeight > divHeight) {
            for (i = 0; i < 20 && scrollHeight > divHeight; i++) {
                if (fontSize >= 16) {
                    fontSize -= 0.5;
                    newTextFont.style.fontSize = fontSize + "px";
                    scrollHeight = newTextFont.scrollHeight;
                }
            }
        }
        //optional: increase font size if free space available
        if (scrollHeight < divHeight) {
            for (i = 0; i < 20 && scrollHeight < divHeight; i++) {
                if (fontSize <= 16) {
                    fontSize += 0.5;
                    newTextFont.style.fontSize = fontSize + "px";
                    scrollHeight = newTextFont.scrollHeight;
                }
            }
        }
    });
}
