import towerMain from '../../../../filesToUse/images/towerOfHanoiAssets/towerMainMenu.png';
import towerSplash from '../../../../filesToUse/images/towerOfHanoiAssets/towerTutorialSplash.png';
import towerSix from '../../../../filesToUse/images/towerOfHanoiAssets/towerSixDisc.png';
import towerSeven from '../../../../filesToUse/images/towerOfHanoiAssets/towerSevenDisc.png';
import towerEight from '../../../../filesToUse/images/towerOfHanoiAssets/towerEightDisc.png';
import towerNine from '../../../../filesToUse/images/towerOfHanoiAssets/towerNineDisc.png';
import towerTen from '../../../../filesToUse/images/towerOfHanoiAssets/towerTenDisc.png';
import prisonMain from '../../../../filesToUse/images/prisonEscapeAssets/MainMenu.png';
import prisonEarly from '../../../../filesToUse/images/prisonEscapeAssets/EarlyGame.png';
import prisonOptions from '../../../../filesToUse/images/prisonEscapeAssets/OptionsMenu.png';
import prisonPrisoner from '../../../../filesToUse/images/prisonEscapeAssets/FirstPrisoner.png';
import prisonCombat from '../../../../filesToUse/images/prisonEscapeAssets/SomeCombat.png';
import prisonCheckpoint from '../../../../filesToUse/images/prisonEscapeAssets/FirstCheckpoint.png';


const towerImages = [
    {
        id: 1,
        image: towerMain
    },
    {
        id: 2,
        image: towerSplash
    },
    {
        id: 3,
        image: towerSix
    },
    {
        id: 4,
        image: towerSeven
    },
    {
        id: 5,
        image: towerEight
    },
    {
        id: 6,
        image: towerNine
    },
    {
        id: 7,
        image: towerTen
    },
];

const prisonImages = [
    {
        id: 1,
        image: prisonMain
    },
    {
        id: 2,
        image: prisonOptions
    },
    {
        id: 3,
        image: prisonEarly
    },
    {
        id: 4,
        image: prisonPrisoner
    },
    {
        id: 5,
        image: prisonCombat
    },
    {
        id: 6,
        image: prisonCheckpoint
    },
];

towerImages.forEach((item) => {
    item.key = `tower-image-${item.id}`;
});

export const ProjectPageGrabber = [
    {
        id: 1,
        title: 'Tower of Hanoi Redux',
        imageSet: towerImages,
        descriptionHeader: "A little background",
        description: 'During my senior year at university, I was challenged to apply my computer graphics skills to\n' +
            'create a unique application. I chose to take on the classic Tower of Hanoi game, building it using\n' +
            'Unity. After the course ended, I was driven to refine and perfect the game, eventually resulting in\n' +
            'the polished version currently showcased on my online portfolio. Despite working independently\n' +
            'on the project, I am proud to present Tower of Hanoi Redux, which can be downloaded from the\n' +
            'Google Play Store and itch.io.',
        itchLink: '<iframe src="https://itch.io/embed/1135151?linkback=true&amp;border_width=0" width="550" height="165"><a href="https://c0c0barbet.itch.io/tower-of-hanoi-redux">Tower of Hanoi Redux by C0C0Barbet</a></iframe>',
        itchLinkMobile: '<a href="https://c0c0barbet.itch.io/tower-of-hanoi-redux">Tower of Hanoi Redux by C0C0Barbet</a>'

    },
    {
        id: 2,
        title: 'Prison Escape Freedom Awaits',
        imageSet: prisonImages,
        descriptionHeader: "A little background",
        description: 'During the summer of 2021, I seized the opportunity to participate in a beginner\'s game jam\n' +
            'hosted on itch.io while job searching. Teaming up with a friend, we challenged ourselves to create\n' +
            'a simple 2D platformer. The experience was both enjoyable and educational, as I had the\n' +
            'opportunity to lead and collaborate with my friend, who was new to Unity. This project pushed\n' +
            'me out of my comfort zone and allowed me to hone my teamwork and leadership skills. The final\n' +
            'product, available on itch.io and my online portfolio, is a testament to our hard work and\n' +
            'creativity.',
        itchLink: '<iframe src="https://itch.io/embed/1120184?linkback=true&amp;border_width=0" width="550" height="165"><a href="https://vesiiy.itch.io/prison-escape">Prison Escape: Freedom Awaits by Vesiy, C0C0Barbet</a></iframe>',
        itchLinkMobile: '<a href="https://vesiiy.itch.io/prison-escape">Prison Escape: Freedom Awaits by Vesiy, C0C0Barbet</a>'
    },
    {
        id: 3,
        title: 'Future Projects',
        imageSet: towerImages,
        descriptionHeader: "About the future",
        description: 'I\'m working on a variety of project at the moment however none of them are quite ready to show\n' +
            'just yet. As soon as I am happy with their progress I\'ll add them to this page so check back here periodically!',
        itchLink: '<iframe src="https://itch.io/embed/1120184?border_width=0" width="550" height="165"><a href="https://vesiiy.itch.io/prison-escape">Prison Escape: Freedom Awaits by Vesiy, C0C0Barbet</a></iframe>',
        itchLinkMobile: '<a href="https://vesiiy.itch.io/prison-escape">Prison Escape: Freedom Awaits by Vesiy, C0C0Barbet</a>'
    }
];
