import React, {useCallback, useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {API} from 'aws-amplify';
import {createCandidate} from '../../graphql/mutations';
import './contactStyling.css';

const ContactMe = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [touched, setTouched] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const {executeRecaptcha} = useGoogleReCaptcha();

    const handleInput = (e) => {
        if (!e.target.checkValidity()) {
            e.target.classList.add('invalid');
        } else {
            e.target.classList.remove('invalid');
        }
    };

    const handleSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            await API.graphql({
                query: createCandidate,
                variables: {
                    input: {
                        name,
                        email,
                        message,
                    },
                },
            });
            e.target.reset();
            setResponseMessage("I look forward to reading your message!");
        },
        [name, email, message]
    );


    const handleReCaptchaVerify = useCallback(async (e) => {
        e.preventDefault();
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha(recaptchaToken)
        if (token) {
            await handleSubmit(e);
        }
        // Do whatever you want with the token
    }, [executeRecaptcha, handleSubmit, recaptchaToken]);

    return (
        <form
            id="email-form"
            className="contact-body"
            onSubmit={handleReCaptchaVerify}
        >
            <div className="container form-contact">
                <div className="row form-header">
                    <h2>Contact Me</h2>
                </div>
                <div className="row form-element">
                    <input
                        className="form-control form-input"
                        type="text"
                        onInput={handleInput}
                        onChange={(e) => setName(e.target.value)}
                        onBlur={(e) => setName(e.target.value)}
                        onFocus={() => setTouched(true)}
                        required
                        pattern="[A-Za-z ]+"
                        title="Names should only contain letters and spaces"
                    />
                    <label className="input-label">Your Name</label>
                </div>
                <div className="row form-element">
                    <input
                        className="form-control form-input"
                        type="email"
                        onInput={handleInput}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={(e) => setEmail(e.target.value)}
                        onFocus={() => setTouched(true)}
                        required
                        pattern="^[\w-.]+@([\w-]+.)+[\w-]{2,4}$"
                        title="Please enter a valid email address"
                    />
                    <label className="input-label">Your Email</label>
                </div>
                <div className="row form-element">
                        <textarea className="form-control form-input"
                                  rows="4"
                                  cols="50"
                                  maxLength="500"
                                  onInput={handleInput}
                                  onChange={(e) => setMessage(e.target.value)}
                                  onBlur={(e) => setMessage(e.target.value)}
                                  onFocus={() => setTouched(true)}
                                  required={true}
                                  title="Tell me something!"
                        />
                    <label className="input-label">
                        Anything Else
                    </label>
                </div>
                <div className="email-message">
                    {responseMessage ?
                        <h5>
                            <b>
                                {responseMessage}
                            </b>
                        </h5>
                        : null}
                </div>
                <div className="row">
                    <button className="form-button btn btn-secondary btn-lg btn-block"
                            type="submit"
                    >
                        Email Me!
                    </button>
                </div>
            </div>
        </form>
    )
};

export default ContactMe;